/*
Block Element Model attempts to control the majority of styling elements on all base pages. This file imports other
files in this folder.
 */
:root {
  --left-gradient: #151515;
  --right-gradient: #464646;
}
html {
  font-size: 100% /* 1rem is now 16px in most browsers */;
  font-size: 62.5% /* 1rem is now 10px in most browsers */;
  font-size: 57.875%;
  font-size: 53.125% /* 1rem is now 8.5px in most browsers */;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: var(--p-light);
  font-family: var(--font-family-b);
  color: var(--text-dark);
  font-size: 1.6rem;
}
a {
  color: var(--c);
}
a:visited {
  color: var(--c);
}
a:hover {
  color: var(--c-dark);
}
h1 {
  font-family: var(--font-family-h);
  font-size: 3.2rem;
  color: var(--c);
  margin-right: 1rem;
}
h2 {
  font-family: var(--font-family-h);
  size: 2rem;
  color: var(--c);
}
.g--flex-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
.g--flex-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.g--flex-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.g--flex-evenly {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.g--flex-around {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.g--flex-between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.g--flex-evenly-like {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@supports not (-ms-ime-align: auto) {
  .g--flex-evenly-like {
    justify-content: space-evenly;
  }
}
.g--flex-around--col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  position: relative;
  flex-flow: column nowrap;
}
.g--flex--col-nowrap {
  flex-flow: column nowrap;
}
.g--flex--align-start {
  align-items: flex-start;
}
.viewport-fixed {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.viewport-fixed--important {
  z-index: 999;
}
.g--bg-lingrad {
  background-image: linear-gradient(to right, var(--left-gradient), var(--right-gradient));
}
.g--bg-none {
  background-color: unset;
}
.g--bg-modal {
  background-color: var(--bg-modal);
}
.g--bg-primary {
  background-color: var(--c);
}
.g--bg-secondary {
  background-color: var(--c2);
}
.g--bg-light {
  background-color: var(--bg-3);
}
.g--pad-b-1 {
  padding-bottom: 1rem;
}
.g--pad-b-2 {
  padding-bottom: 2rem;
}
.g--pad-b-10 {
  padding-bottom: 10rem;
}
.g--pad-t-10 {
  padding-top: 10rem;
}
.g--pad-t-1 {
  padding-top: 1rem;
}
.g--pad-t-2 {
  padding-top: 2rem;
}
.g--text-left {
  text-align: left;
}
.g--text-center {
  text-align: center;
}
.g--text-right {
  text-align: right;
}
.g--text-white {
  color: #fff;
}
.g--text-c2 {
  color: var(--c2);
}
.g--text-c {
  color: var(--c);
}
.g--pos-00 {
  top: 0;
  left: 0;
  width: 100%;
}
.g--pos-0r {
  top: 0;
  right: 0;
}
.g--pad-l-2 {
  padding-left: 2rem;
}
.g--pad-r-2 {
  padding-right: 2rem;
}
.g--icon-col-width {
  width: 2rem;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.modal {
  width: 50%;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  border-radius: 0.8rem;
  box-sizing: border-box;
  padding: 4rem 4rem;
  -webkit-box-shadow: 0 3px 7px rgba(0,0,0,0.3);
  -moz-box-shadow: 0 3px 7px rgba(0,0,0,0.3);
  box-shadow: 0 3px 7px rgba(0,0,0,0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  background-color: var(--white);
  position: relative;
  text-align: center;
}
.modal--small {
  padding: 2rem 2rem;
  top: -25%;
}
@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .modal--small {
    top: unset;
    padding: 2rem 2rem;
  }
}
.message {
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  border-radius: 0.8rem;
  padding: 1.2rem 3rem 1.2rem 0.9rem;
  text-align: left;
  font-style: italic;
  font-size: 1.6rem;
  position: relative;
  font-weight: bold;
}
.message--warning {
  background-color: rgba(255,238,0,0.369);
}
.message--danger {
  background-color: rgba(234,76,136,0.302);
}
.message--info {
  background-color: #3a8bbb;
}
.message--success {
  background-color: #42e86c;
}
.message--frontend {
  background-color: #d3d3d3;
}
.message--position {
  position: fixed;
  top: 9rem;
  left: calc(50% - 35vw);
  z-index: 100000;
  width: 70vw;
}
.form-group {
  position: relative;
  padding-bottom: 1rem;
}
.form-select {
  width: 100%;
  padding: 0 1.8rem 0 1.8rem;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1rem;
}
.form-select p {
  font-size: 1.5rem;
  color: var(--text-2);
  display: inline;
  padding-left: 0.5rem;
}
.form--page {
  width: 100%;
  background-color: var(--bg-3);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  margin: auto;
  padding: 1.5rem;
  box-sizing: border-box;
}
.form--page--white {
  background-color: #fff;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1.5rem;
  padding: 1rem 1rem 1rem 2rem;
  display: block;
  width: 100%;
  border: none;
  -webkit-border-radius: 1.8rem;
  -moz-border-radius: 1.8rem;
  border-radius: 1.8rem;
  box-sizing: border-box;
  transition: 0.25s;
  color: var(--text-4);
}
input:focus {
  outline: none;
  background-color: var(--bg-3);
}
input:hover {
  background-color: var(--bg-3);
}
input::placeholder {
  color: var(--text-2);
  opacity: 1;
}
input:-ms-input-placeholder {
  color: var(--text-2);
}
input::-ms-input-placeholder {
  color: var(--text-2);
}
input:focus ~ label,
input:valid ~ label {
  top: -2rem;
  font-size: 1.2rem;
  color: var(--header);
}
input:focus ~ .input_bar:before,
input:focus ~ .input_bar:after {
  width: 50%;
}
.input--checkbox {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
  width: unset;
  display: inline;
}
.input--radio {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
  width: unset;
  display: inline;
}
.input_static-bar {
  content: '';
  height: 1px;
  width: calc(100% - 3.6rem);
  bottom: 1px;
  position: relative;
  display: block;
  background: var(--white-hover-outline);
  left: 1.8rem;
}
.input_bar {
  position: relative;
  display: block;
  width: 100%;
}
.input_bar:before,
.input_bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--c2);
  transition: 0.2s ease all;
}
.input_bar:before {
  left: calc(50% - 1.8rem);
}
.input_bar:after {
  right: calc(50% - 1.8rem);
}
.input_validate {
  color: #f00;
  font-size: 1.2rem;
  font-style: italic;
  text-align: left;
  padding: 0 1.8rem;
  width: 100%;
  box-sizing: border-box;
}
.input_span {
  height: 3.6rem;
  line-height: 3.6rem;
  padding: 0 1.8rem;
  display: block;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.5rem;
}
.input_width_50 {
  width: 49.5%;
  display: inline-block;
}
.input_color {
  height: 3rem;
  width: 4rem;
  padding: 0px;
  margin: 0px;
  display: inline-block;
  position: relative;
  top: 0.5rem;
}
label {
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 0 1.8rem;
  height: 3.6rem;
  line-height: 3.6rem;
}
textarea {
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--white-hover-outline);
  background-color: var(--bg-3);
}
textarea:focus {
  outline: var(--c2) auto 5px;
}
.btn {
  background-color: var(--bg-4);
  padding: 0.5rem 1.3rem;
  margin: 0rem 1rem;
  border: 1px solid var(--c2);
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  color: var(--c2);
  font-weight: bold;
  display: inline-block;
  height: 2rem;
  width: unset;
  font-size: 1.5rem;
  position: relative;
  text-decoration: unset;
  line-height: 2rem;
}
.btn:focus {
  color: var(--text-1);
  outline: unset;
}
.btn:hover {
  background-color: var(--white-hover);
  cursor: pointer;
  color: var(--c2);
}
.btn:visited {
  color: var(--c2);
}
.btn .tooltiptext {
  visibility: hidden;
  opacity: 0;
  width: 8rem;
  background-color: var(--bg-2);
  color: var(--text-2);
  text-align: center;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -4rem;
  font-size: 1.1rem;
  transition: visibility 0s, opacity 0.4s linear 0.8s;
}
.btn .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--bg-2) transparent;
}
.btn:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltipped {
  position: relative;
}
.tooltipped .tooltiptext {
  visibility: hidden;
  opacity: 0;
  width: 8rem;
  background-color: var(--bg-2);
  color: var(--text-2);
  text-align: center;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -4rem;
  font-size: 1.1rem;
  transition: visibility 0s, opacity 0.4s linear 0.8s;
}
.tooltipped .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--bg-2) transparent;
}
.tooltipped:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.btn--right {
  position: absolute;
  right: 0px;
  margin-right: 1.3rem;
}
.btn--tall {
  height: 3.2rem;
}
.btn--strong {
  color: var(--white);
  font-weight: bold;
  background-color: var(--c2);
}
.btn--strong:hover {
  background-color: var(--c2-dark);
  cursor: pointer;
  color: var(--white);
}
.btn--greyed {
  color: var(--text-2);
  border-color: var(--text-2);
}
.btn--greyed:hover {
  color: var(--text-2);
}
.btn-google {
  font-size: 2rem;
  line-height: 2rem;
}
.btn-inactive {
  color: var(--text-4-w);
  border-color: var(--text-4-w);
  display: none;
}
.btn-inactive:hover {
  background-color: var(--white-hover);
  cursor: default;
}
.btn-close {
  position: absolute;
  font-style: normal;
  right: 1rem;
  background: var(--white);
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: 1.6rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
/* line-height: 1.8rem; */
  color: var(--p-dark);
  top: calc(50% - 0.9rem);
}
.btn-close:hover {
  background: var(--white-hover);
  color: var(--p-dark);
  cursor: pointer;
}
.btn-modal-close {
  position: absolute;
  font-style: normal;
  left: 1rem;
  top: 1rem;
  background: var(--white);
  width: 2.9rem;
  height: 2.9rem;
  text-align: center;
  font-size: 2.2rem;
  -webkit-border-radius: 1.45rem;
  -moz-border-radius: 1.45rem;
  border-radius: 1.45rem;
/* line-height: 1.8rem; */
  color: var(--p-dark);
  border: 1px solid var(--header);
}
.btn-modal-close:hover {
  background: var(--white-hover);
  color: var(--p-dark);
  cursor: pointer;
}
.btn-text {
  height: 3.3rem;
}
.btn--image {
  position: relative;
  object-fit: fill;
  width: 100px;
  height: 100px;
  z-index: 2;
}
.btn--image:hover {
  cursor: pointer;
}
.btn-payment {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  -webkit-box-shadow: 0px 8px 15px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 8px 15px rgba(0,0,0,0.1);
  box-shadow: 0px 8px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  margin: 1rem auto;
}
.btn-payment:hover {
/*background-color: #2EE59D;*/
  -webkit-box-shadow: 0px 10px 10px var(--c2);
  -moz-box-shadow: 0px 10px 10px var(--c2);
  box-shadow: 0px 10px 10px var(--c2);
  transform: translateY(-7px);
}
.btn-sign-up {
  background: #fff;
  color: var(--c2);
  font-weight: bold;
  height: 5rem;
  width: 20rem;
  line-height: 5rem;
  text-align: center;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  border: 1px solid var(--c2);
  transition: 0.2s;
  margin: auto;
  z-index: 99;
}
.btn-sign-up:focus {
  color: var(--c2);
  outline: unset;
}
.btn-sign-up:hover {
  background-color: var(--c2);
  cursor: pointer;
  color: #fff;
}
.btn-sign-up:visited {
  color: var(--c2);
}
.btn-sign-up--inv {
  background: var(--c2);
  color: #fff;
}
.btn-sign-up--inv:focus {
  color: #fff;
  outline: unset;
}
.btn-sign-up--inv:hover {
  background-color: #fff;
  cursor: pointer;
  color: var(--c2);
}
.btn-sign-up--inv:visited {
  color: #fff;
}
.btn-sign-up__bottom {
  position: absolute;
  bottom: 3rem;
}
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.4rem;
}
@-moz-document url-prefix() {
  table {
    border-collapse: unset;
    border-spacing: 0;
  }
}
thead {
  background-attachment: fixed;
  background: var(--c);
}
thead > tr {
  line-height: 3.6rem !important;
  text-align: left;
  color: #fff;
}
thead th {
  position: relative;
}
.table-list-container {
  width: 100%;
  padding: 0rem 0rem;
  box-sizing: border-box;
  max-height: 85rem;
  overflow: scroll;
}
.table-list tr {
  line-height: 1.7rem; /* 2.4rem */
  position: relative;
}
.table-list tr:nth-child(odd) {
  background-color: var(--p-light);
}
.table-list tr:nth-child(even) {
  background-color: var(--p-light);
}
.table-list tr.historical {
  background-color: #ccc;
}
.table-list tr:hover {
/*border-bottom: 1px solid var(--p-dark);*/
  background-color: var(--white-hover);
  cursor: pointer;
}
.table-list-no-pointer tr:hover {
  cursor: default;
}
.table-list tr.selected {
  background-color: var(--bg-2);
  color: var(--white);
}
.table-list tr.activated {
  background-color: var(--text-4-w);
/*color: var(--white);*/
}
.table-list tr.activated.selected {
  background-color: var(--bg-2);
}
.table-list td {
  border-bottom: 1px solid var(--white-hover-outline);
}
.table-btns {
  position: absolute;
  right: 0px;
}
.ico-google {
  font-size: 2rem;
  position: relative;
  top: 0.2rem;
/*left: 0.3rem;*/
}
.ico-pointer:hover {
  cursor: pointer;
}
.ico-location {
  font-size: 1.4rem;
}
.ico-table-head {
  font-size: 2rem;
/*position: absolute;*/
/*top: 0.9rem;*/
/*right: 0.9rem;*/
  position: relative;
  top: 0.5rem;
  cursor: pointer;
}
.ico-table-head::after {
  content: 'arrow_drop_down';
}
.ico-table-head-active::after {
  content: 'arrow_drop_down_circle';
}
.ico-visible-head {
  font-size: 2rem;
  position: relative;
  top: 0.5rem;
  cursor: pointer;
}
.ico-visible-head::after {
  content: 'visibility';
}
.ico-visible-head-active::after {
  content: 'visibility_off';
}
.ico-header-link {
  cursor: pointer;
  color: var(--c2);
}
.ico-header-link:hover {
  color: var(--c2-dark);
}
.no-hover:hover {
  color: var(--c2);
}
.selected a {
  color: var(--white);
}
.selected a:hover {
  color: var(--white-hover);
}
