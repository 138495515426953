.center {text-align: center;}
.left {text-align: left;}
.right {text-align: right;}

.small-font {
    font-size: 1.3rem;
}

.logout{
    position: absolute;
    right:0.5rem;
    top:0.5rem;
}

.container {
    width:100%;
    padding-top: 0rem;
    padding-bottom: 3rem;
    padding-left: 5rem;
    padding-right: 5rem;
    position: relative;
    box-sizing: border-box;
}

@media screen and (max-width: 767px) { /*479 / 650*/
/* start of phone styles */
    .container {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 479px) { /*479 / 650*/
/* start of phone styles */
    .phone-no-show {
        display: none;
    }
}