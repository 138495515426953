fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:before,
.multiselect__spinner:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 1.5rem;
  touch-action: manipulation;
}
input.multiselect__input{
}
input.multiselect__input:focus{
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  /*min-height: 3.6rem;*/
  height: 3.6rem;
  text-align: left;
  color: var(--text-4);
}
.multiple.multiselect {
  height: 10.8rem;
}
.multiple--single.multiselect {
  height: 3rem;
}

.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  /*border-bottom-left-radius: 0;*/
  /*border-bottom-right-radius: 0;*/
}
.multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__single {
  position: relative;
  display: inline-block;
  /*min-height: 20px;*/
  height: 3.6rem;
  line-height: 3.6rem;
  /*border: none;*/
  /*border-radius: 5px;*/
  /*background: #fff;*/
  padding: 0 0 0 5px;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  /*margin-bottom: 8px;*/
  vertical-align: top;
}
.multiselect__input::placeholder {
  color: var(--text-2);
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  /*margin-bottom: 8px;*/
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  /*min-height: 40px;*/
  height: 3.6rem;
  display: block;
  padding: 0rem 40px 0rem 0px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 1.3rem;
}
.multiple .multiselect__tags {
  height: 10.8rem;
  overflow: scroll;
}
.multiple--single .multiselect__tags {
  height: unset;
  overflow: scroll;
}
/*.multiselect__tags input::placeholder { !* Chrome, Firefox, Opera, Safari 10.1+ *!*/
    /*color: var(--p-dark);*/
    /*opacity: 1; !* Firefox *!*/
/*}*/

/*.multiselect__tags input::placeholder { !* Internet Explorer 10-11 *!*/
    /*color: red;*/
/*}*/

/*.multiselect__tags input::placeholder  { !* Microsoft Edge *!*/
    /*color: red;*/
/*}*/


.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiple .multiselect__tag {
  background: var(--c2);
  color: var(--white);
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 2.647rem;
  text-align: center;
  line-height: 2.647rem;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "X";
  color: var(--text-4-w);
  font-size: 1.0rem;
  position: relative;
  top: -0.3rem;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: var(--c2-dark);
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: white;
}
.multiselect__current {
  line-height: 16px;
  /*min-height: 40px;*/
  height: 2rem;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 0px 0px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}
.multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 40px;
  height: 3.6rem;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #999999 transparent transparent transparent;
  content: "";
}
.multiselect__placeholder {
  color: var(--text-2);
  opacity: 1; /* Firefox */
  font-size:1.5rem;
  /*color: #00ff0f;*/
  display: inline-block;
  line-height: 3.6rem;
  padding-left: 5px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 0px 1rem;
  /*min-height: 40px;*/
  height: 2.4rem;
  line-height: 2.4rem;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 2.4rem;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 1.0rem;
}
.multiselect__option--highlight {
  background: var(--white-hover);
  outline: none;
  /*color: white;*/
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: var(--white-hover);
  /*color: white;*/
}
.multiselect__option--selected {
  background: var(--text-4-w);
  color: var(--white);
  font-weight: bold;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: var(--bg-4);
}
.multiselect__option--selected.multiselect__option--highlight {
  background: var(--white-hover);
  color: var(--text-4);
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: var(--white-hover);
  content: attr(data-deselect);
  color: var(--text-4);
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
*[dir="rtl"] .multiselect {
  text-align: right;
}
*[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
*[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0px 40px;
}
*[dir="rtl"] .multiselect__content {
  text-align: right;
}
*[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
*[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
*[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}