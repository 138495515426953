:root {
    /*
    Darkest background with off-white text and white strong text
     */
    --bg-1: #252525;
    --bg-2: rgb(48,48,48);
    --bg-3: #f4f4f4;
    --bg-4: #fff;

    --text-1: #c6c6c6;
    --text-2: #bbb;
    --text-3: #444;
    --text-4: #444;

    --text-1-s: #fff;
    --text-2-s: #fff;  /* this is strong text */
    --text-3-w: #fff;
    --text-4-w: #bbb;

    --c3: #2abd65;
    --c4: #e7861c;
    --modal-bg: rgba(23, 23, 31, 0.5);
    --bg-modal: rgba(23, 23, 31, 0.5);
    --white-hover: #e6e6e6;

    --text-dark: #1c1c1c;

    --p-light: #ffffff;
    --p-light2: #f3f3f3;
    /*--p-light-offset: #fcfcfc;*/
    --primary: #eceff1;
    --p-dark: #babdbe;

    --header: #3d177f;
    --header-hover: rgb(39, 27, 75);
    --header-light: #d6c1ff;
    /*--modal-bg: rgba(127, 23, 105, 0.50);*/

    --s-light: #21B6A8;
    --secondary: #177F75;
    --s-dark: #155046;

    --selection-hover: #cfcfcf;


    --t-light: #FFF4CB;
    --tertiary: #B69521;
    --t-dark: #6f4f20;

    --white: #ffffff;

    --white-hover-outline: #afafab;
}

.darkest {
    background-color: var(--bg-1);
    color: var(--text-1);
}
.darkest strong {
    color: var(--text-1-s);
}

.dark{
    background-color: var(--bg-2);
    color: var(--text-2);
}
.dark strong {
    color: var(--text-2-s);
}

.light {
    background-color: var(--bg-3);
    color: var(--text-3);
}
.light strong {
    font-weight: bold;
}
.light h1 {
    size: 2.5rem;
    color: var(--c);
    margin-right: 1rem;
}

.lightest {
    background-color: var(--bg-4);
}
.lightest strong {
    font-weight: bold;
}

.white {
    background-color: white;
}

.white h1 {
    size: 2.5rem;
    color: var(--c);
    margin-right: 1rem;
}